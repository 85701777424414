export const commonError = {
  zh: {
    TIMEOUT: '请求超时，请稍后重试',
    NET_ERROR: '网络异常，请稍后再试',
    UNKNOWN_ERROR: '未知错误',
    SERVER_ERROR: '服务器错误，请稍后再试',
  },
  en: {
    TIMEOUT: 'request timeout, please try again later',
    NET_ERROR: 'network error, please try again later',
    UNKNOWN_ERROR: 'unknown error',
    SERVER_ERROR: 'server error, please try again later',
  },
};
