export * from './request';

export function getBackUrl() {
  const paramStr = window.location.search;
  const urlParams = new URLSearchParams(paramStr);
  const backUrl = decodeURIComponent(urlParams.get('url') || '');
  return backUrl;
}

export function getType() {
  const paramStr = window.location.search;
  const urlParams = new URLSearchParams(paramStr);
  const type = decodeURIComponent(urlParams.get('type') || '');
  return type;
}
