import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  redirect,
} from 'react-router-dom';
import {
  Root,
  Login,
  Register,
  NotFound,
  loginLoader,
  Reset,
  ChangePwd,
  ChangePwdLoader,
  ErrorPage,
} from './routes';
import { getBackUrl } from './utils';
import { environment } from './environments/environment';
import './i18n';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    loader: () => {
      const backUrlInUrl = getBackUrl();
      const backUrlInSessionStorage = window.sessionStorage.getItem('back_url');
      if (backUrlInUrl) window.sessionStorage.setItem('back_url', backUrlInUrl);
      const backUrl = backUrlInUrl || backUrlInSessionStorage;
      if (!backUrl) {
        return redirect('/404');
      }
      if (environment.sites.length > 0) {
        try {
          const host = new URL(backUrl).host;
          const isValid = environment.sites.some((site) => site === host);
          if (!isValid) {
            return redirect('/404');
          }
        } catch (e) {
          return redirect('/404');
        }
      }
      return null;
    },
    children: [
      {
        index: true,
        loader: loginLoader,
        element: <Login />,
      },
      {
        path: 'register',
        element: <Register />,
        loader: () => {
          const client = environment.client;
          if (client === 'dinger') {
            return redirect('/404');
          } else {
            return null;
          }
        },
      },
      {
        path: 'change-password',
        loader: ChangePwdLoader,
        element: <ChangePwd />,
      },
      {
        path: 'reset-password',
        element: <Reset />,
        loader: () => {
          const client = environment.client;
          if (client === 'dinger') {
            return redirect('/404');
          } else {
            return null;
          }
        },
      },
    ],
  },
  {
    path: '/404',
    element: <NotFound />,
  },
  {
    path: '*',
    element: <Navigate to="/404" replace />,
  },
]);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <RouterProvider router={router} />
  </StrictMode>
);
