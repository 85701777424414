/**
 * 仅处理非成功状态的 http 状态码，即 1xx, 3xx, 4xx, 5xx;
 * 5xx 统一视为服务器错误，不具体区分;
 * http code:
 * 1xx: Informational
 * 2xx: Success
 * 3xx: Redirection
 * 4xx: Client Error
 * 5xx: Server Error
 */

export const httpCode = {
  zh: {
    100: '请求响应正常，请继续发送请求，如果请求已完成则忽略',
    101: '服务器正在切换协议，请等待',
    102: '服务器正在处理请求，请等待',
    103: '建议客户端预加载资源，在服务器返回最终结果前',
    300: '请求有多个可能的响应，请选择其中一个响应',
    301: '请求资源的 URL 已被永久更改',
    302: '请求资源的 URL 已被临时更改',
    303: '请求的资源可在另一个 URL 上访问',
    304: '请求的资源未更新',
    305: '请求的资源必须通过代理访问',
    307: '请求资源的 URL 已被临时更改',
    308: '请求资源的 URL 已被永久更改',
    400: '请求语法错误，服务器无法理解，请检查请求的语法',
    401: '请求未经授权，请检查授权信息',
    403: '请求无权限访问，请检查权限',
    404: '请求的资源不存在，请检查资源 URL',
    405: '请求的方法不允许，请检查请求的方法',
    406: '请求的资源不满足请求的内容特性，请检查请求的内容',
    407: '请求的资源需要代理认证，请检查代理认证信息',
    408: '请求超时',
    409: '请求冲突，请检查请求的冲突信息',
    410: '请求的资源已被删除，请检查资源 URL',
    411: '请求的资源需要 Content-Length 字段',
    412: '先决条件不满足，请检查先决条件',
    413: '请求的资源大小超过服务器限制',
    414: '请求的资源 URL 太长',
    415: '请求的资源不支持请求的内容类型',
    416: '请求的资源不满足请求的 Range 范围',
    417: '服务器无法满足 Expect 的请求期望',
    418: '服务器拒绝冲泡咖啡，因为它是个茶壶',
    422: '请求格式正确，但由于某些原因服务器无法响应',
    423: '请求的资源正在被锁定，请稍后再试',
    424: '请求失败，由于前一个请求失败',
    425: '服务器拒接处理该请求，该请求可能会被“重放”',
    426: '服务器拒绝处理该请求，请切换到其他协议',
    428: '服务器端要求发送条件请求',
    429: '请求过于频繁，请稍后再试',
    431: '服务器拒绝处理该请求，请求中的首部字段过大',
    444: '服务器未返回任何响应并关闭了连接',
    449: '请在适当调整后，重新发送请求',
    450: 'Windows 家长控制被打开并阻止访问该资源',
    451: '因法律原因无法访问',
    499: '客户端已关闭该请求',
  },
  en: {
    100: 'Everything so far is OK and that the client should continue with the request or ignore it if it is already finished.',
    101: 'The server is switching to a protocol.',
    102: 'The server has received and is processing the request, but no response is available yet.',
    103: 'The user agent should begin preloading the resources as the server prepares a final response.',
    300: 'The request has more than one possible response. The user-agent or user should choose one of them.',
    301: 'The URL of the requested resource has been changed permanently.',
    302: 'The URL of the requested resource has been changed temporarily.',
    303: 'The requested resource can be found at another URL.',
    304: 'The requested resource has not been modified since the last request.',
    305: 'The requested resource must be accessed through a proxy.',
    307: 'The URL of the requested resource has been changed temporarily.',
    308: 'The URL of the requested resource has been changed permanently.',
    400: 'The request could not be understood by the server due to incorrect syntax.',
    401: 'The request requires user authentication information.',
    403: 'The request does not have access rights to the content.',
    404: 'The requested resource could not be found.',
    405: 'The request method is not allowed for the requested resource.',
    406: `The server doesn't find any content that conforms to the criteria given by the user agent in the Accept header sent in the request.`,
    407: 'The client must first authenticate itself with the proxy.',
    408: 'The server timed out waiting for the request.',
    409: 'The request could not be completed because of a conflict in the request.',
    410: 'The requested resource is no longer available on the server and no forwarding address is known.',
    411: 'The server refuses to accept the request without a Content-Length header field.',
    412: 'The precondition given in one or more of the request header fields evaluated to false when it was tested on the server.',
    413: 'The server is refusing to process a request because the request entity is larger than the server is willing or able to process.',
    414: 'The server is refusing to service the request because the request URI is longer than the server is willing to interpret.',
    415: 'The server is refusing to service the request because the entity of the request is in a format not supported by the requested resource for the requested method.',
    416: 'The server is refusing to service the request because the range specified in the request is not satisfiable.',
    417: 'The expectation given in the request header field could not be met by the server.',
    418: 'The server is a teapot.',
    422: 'The server understands the content type and syntax of the request entity, but still server is unable to process the request for some reason.',
    423: 'The resource that is being accessed is locked.',
    424: 'The request failed due to failure of a previous request.',
    425: 'The server is unwilling to risk processing a request that might be replayed.',
    426: 'The server refuses to perform the request using the current protocol but might be willing to do so after the client upgrades to a different protocol.',
    428: 'The server requires the request to be conditional.',
    429: 'The user has sent too many requests in a given amount of time (“rate limiting”).',
    431: 'The server is unwilling to process the request because its header fields are too large.',
    444: 'The server returns no information to the client and closes the connection.',
    449: 'The request should be retried after performing the appropriate action.',
    450: 'Windows Parental Controls are turned on and are blocking access to the given webpage.',
    451: 'The user-agent requested a resource that cannot legally be provided.',
    499: 'The connection is closed by the client while HTTP server is processing its request, making the server unable to send the HTTP header back.',
  },
};
