/* eslint-disable no-useless-escape */
import {
  useState,
  useCallback,
  ChangeEventHandler,
  MouseEventHandler,
} from 'react';
import { LockClosedIcon } from '@heroicons/react/solid';
import { validateToken, login } from '../api';
import { Link } from 'react-router-dom';
import { Loading, Alert } from '../components';
import type { AlertProps } from '../components';
import { z } from 'zod';
import { getType } from '../utils';
import { environment } from '../environments/environment';
import { useTranslation } from 'react-i18next';

// 跳转到需要认证的系统
const redirectToSystem = (token: string) => {
  const backUrl = window.sessionStorage.getItem('back_url') as string;
  const url = new URL(backUrl);
  const host = url.host;
  const protocol = url.protocol;
  const params = new URLSearchParams(url.search);
  params.append('token', token);
  const newUrl = protocol + '//' + host + '/?' + params.toString();
  console.log(newUrl);
  window.location.replace(newUrl);
};

export async function loginLoader() {
  const tokenInLocalStorage = localStorage.getItem('token');
  const type = getType();
  if (type === 'signout') {
    window.localStorage.removeItem('token');
    return null;
  }
  if (tokenInLocalStorage) {
    try {
      const { data } = await validateToken(tokenInLocalStorage);
      if (data?.user) {
        redirectToSystem(tokenInLocalStorage);
      } else {
        window.localStorage.removeItem('token');
      }
    } catch (e) {
      console.error(e);
      window.localStorage.removeItem('token');
    }
  }
  return null;
}

export function Login() {
  const { t } = useTranslation('loginsystem');

  const schema = z.object({
    username: z.string().min(1, t('username_cannot_be_empty')),
    password: z
      .string()
      .min(1, t('password_cannot_be_empty'))
      .regex(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#\$%\^&\*\+\-\[\]\\\|:\?<>~\)\("'\.,=_`/;{}]{8,25}$/,
        t('password_must_contain_uppercase_lowercase_numbers_length_8_25')
      ),
  });

  type LoginForm = z.infer<typeof schema>;
  const client = environment.client;
  const [loading, setLoading] = useState(false);
  const [alertData, setAlertData] = useState<AlertProps>({
    type: 'yellow',
    content: '',
  });

  // login form
  const [loginForm, setLoginForm] = useState<LoginForm>({
    username: '',
    password: '',
  });

  // login form change handler
  const onLoginFormChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const { name, value } = event.target;
      setLoginForm((prev) => ({ ...prev, [name]: value }));
    },
    [setLoginForm]
  );

  // 校验登录表单参数
  const chkLoginParams = () => {
    const result = schema.safeParse(loginForm);
    if (!result.success) {
      setAlertData({
        type: 'yellow',
        content: result.error.issues[0].message,
      });
      return false;
    }
    setAlertData({
      type: 'yellow',
      content: '',
    });
    return true;
  };

  // 提交表单
  const onSubmit: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault(); // 阻止submit事件
    const chkRes = chkLoginParams();
    if (!chkRes) return;
    setAlertData({
      type: 'yellow',
      content: '',
    });
    setLoading(true);
    login(loginForm.username, loginForm.password)
      .then((res) => {
        const { token, user } = res.data;
        if (token) {
          window.localStorage.setItem('token', token);
          window.localStorage.setItem('user', JSON.stringify(user));
          setLoading(false);
          setAlertData({ type: 'green', content: t('login_successful') });
          setTimeout(() => {
            redirectToSystem(token);
          }, 1000);
        }
      })
      .catch((err) => {
        setLoading(false);
        const error = err?.response?.data?.detail || '';
        setAlertData({
          type: 'red',
          content: t('login_failed') + t('comma') + error,
        });
      });
  };
  return (
    <>
      <h1 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
        {t('login')}
      </h1>
      <Loading loading={loading} />
      <form className="mt-8 space-y-6">
        <input type="hidden" name="remember" defaultValue="true" />
        <Alert type={alertData.type} content={alertData.content} />
        <div className="shadow-sm">
          <div className="mb-3 rounded">
            <label htmlFor="email-address" className="sr-only">
              {t('username')}
            </label>
            <input
              id="email-address"
              name="username"
              type="email"
              autoComplete="email"
              aria-required
              aria-label={t('username')}
              value={loginForm.username}
              required
              className="bg-white/20 lg:bg-white appearance-none rounded relative block w-full px-3 py-2.5 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-zinc-500 focus:border-zinc-500 focus:z-10 sm:text-sm"
              placeholder={t('username')}
              onChange={onLoginFormChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="sr-only">
              {t('password')}
            </label>
            <input
              id="password"
              name="password"
              type="password"
              aria-label={t('password')}
              value={loginForm.password}
              autoComplete="current-password"
              required
              className="bg-white/20 lg:bg-white appearance-none rounded relative block w-full px-3 py-2.5 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-zinc-500 focus:border-zinc-500 focus:z-10 sm:text-sm"
              placeholder={t('password')}
              onChange={onLoginFormChange}
            />
          </div>
        </div>
        <div>
          <button
            onClick={onSubmit}
            className="group relative w-full flex justify-center py-2.5 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-zinc-600 hover:bg-zinc-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-zinc-500"
          >
            <span className="absolute left-0 inset-y-0 flex items-center pl-3">
              <LockClosedIcon
                className="h-5 w-5 text-zinc-500 group-hover:text-zinc-400"
                aria-hidden="true"
              />
            </span>
            {t('login')}
          </button>
        </div>
        {client === 'dinger' ? null : (
          <div className="flex flex-row items-center justify-between">
            <div className="text-sm">
              <label className="font-medium text-zinc-600 hover:text-zinc-500 cursor-pointer">
                <Link to={'/reset-password'}>{t('forgot_password')}</Link>
              </label>
            </div>
            <div className="text-sm">
              <label className="font-medium text-zinc-600 hover:text-zinc-500 cursor-pointer">
                <Link to={'/register'}>{t('register_new_account')}</Link>
              </label>
            </div>
          </div>
        )}
      </form>
    </>
  );
}

export default Login;
