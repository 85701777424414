/**
 * 响应错误码，优先于 HTTP 状态码
 * 如果有响应错误码，则优先使用响应错误码的提示信息
 */
export const responseCode = {
  zh: {
    3001: '无有效登录凭证',
    3002: '登录凭证已过期',
    4000: '校验失败',
    4001: '创建失败',
    4002: '更新失败',
    4003: '删除失败',
    4004: '校验失败',
    4010: '上传失败',
    4100: '内部配置错误',
    6001: '未提供有效校验信息',
    6002: '验证信息错误',
    6003: '无权执行当前操作',
    8002: '传入了错误参数',
    8003: '错误的请求格式',
    8004: '未找到目标对象',
    8005: '对象已存在',
  },
  en: {
    3001: 'no valid login credential',
    3002: 'login credential expired',
    4000: 'validation error',
    4001: 'create error',
    4002: 'update error',
    4003: 'delete error',
    4004: 'validation error',
    4010: 'upload error',
    4100: 'internal configuration error',
    6001: 'invalid validation info',
    6002: 'validation info error',
    6003: 'no permission to execute current operation',
    8002: 'invalid parameters',
    8003: 'invalid request format',
    8004: 'not found target object',
    8005: 'object already exists',
  },
};
