import { useRouteError } from 'react-router-dom';

export function ErrorPage() {
  const error = useRouteError() as Record<'statusText' | 'message', string>;
  console.error(error);

  return (
    <div className="flex h-screen w-screen flex-col items-center justify-center space-y-8">
      <h1 className="text-bold mb-0 font-mono text-8xl">Oops!</h1>
      <p className="mb-0 text-2xl">Sorry, an unexpected error has occurred.</p>
      <p className="mb-0 text-2xl">
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}
