/* eslint-disable no-useless-escape */
import {
  useState,
  useCallback,
  MouseEventHandler,
  ChangeEventHandler,
} from 'react';
import { LockClosedIcon } from '@heroicons/react/solid';
import { Loading, Alert } from '../components';
import type { AlertProps } from '../components';
import { register, getTicketToRegister } from '../api';
import { Link, redirect } from 'react-router-dom';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';

export function Register() {
  const { t } = useTranslation('loginsystem');

  const schema = z.object({
    name: z.string().min(1, t('username_cannot_be_empty')),
    email: z
      .string()
      .min(1, t('email_cannot_be_empty'))
      .email(t('email_format_incorrect')),
    ticket: z.string().min(1, t('verification_code_cannot_be_empty')),
    mobile: z
      .string()
      .min(1, t('mobile_number_cannot_be_empty'))
      .regex(/^1[3456789]\d{9}$/, t('mobile_number_format_incorrect')),
    password: z
      .string()
      .min(1, t('password_cannot_be_empty'))
      .regex(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#\$%\^&\*\+\-\[\]\\\|:\?<>~\)\("'\.,=_`/;{}]{8,25}$/,
        t('password_format_incorrect')
      ),
  });

  type RegisterForm = z.infer<typeof schema>;

  const [loading, setLoading] = useState(false);
  const [alertData, setAlertData] = useState<AlertProps>({
    type: 'yellow',
    content: '',
  });

  const [ticketStatus, setTicketStatus] = useState<
    'success' | 'error' | 'default' | 'loading'
  >('default');

  const [countdown, setCountdown] = useState(0);

  const emptyRegisterForm = {
    name: '',
    email: '',
    ticket: '',
    mobile: '',
    password: '',
  };
  // register form
  const [registerForm, setRegisterForm] =
    useState<RegisterForm>(emptyRegisterForm);

  // register form change handler
  const onRegisterFormChange: ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (event) => {
        const { name, value } = event.target;
        setRegisterForm((prev) => ({ ...prev, [name]: value }));
      },
      [setRegisterForm]
    );

  // send validate code
  const onSendTicket: MouseEventHandler<HTMLButtonElement> = useCallback(
    async (event) => {
      event.stopPropagation();
      setAlertData({ type: 'yellow', content: '' });
      // validate email
      const result = schema.shape.email.safeParse(registerForm.email);
      if (!result.success) {
        setAlertData({
          type: 'yellow',
          content: result.error.issues[0].message,
        });
        return;
      }
      setTicketStatus('loading');
      try {
        const { data } = await getTicketToRegister(registerForm.email);
        if (data) {
          setTicketStatus('success');
          setCountdown(60);
          const timer = setInterval(() => {
            setCountdown((prev) => {
              if (prev === 0) {
                clearInterval(timer);
                setTicketStatus('default');
                return 0;
              }
              return prev - 1;
            });
          }, 1000);
        } else {
          setTicketStatus('error');
          setAlertData({
            type: 'red',
            content: t('send_failed_please_try_again_later'),
          });
        }
      } catch (error: any) {
        setTicketStatus('error');
        const e = error?.response?.data?.detail || '';
        setAlertData({
          type: 'red',
          content: t('send_failed') + t('comma') + e,
        });
      }
    },
    [registerForm.email, setTicketStatus, setCountdown]
  );

  // 校验注册表单参数
  const chkRegisterParams = () => {
    const result = schema.safeParse(registerForm);
    if (result.success === false) {
      setAlertData({
        type: 'yellow',
        content: result.error?.issues?.[0]?.message,
      });
      return false;
    }
    setAlertData({
      type: 'yellow',
      content: '',
    });
    return true;
  };

  // 提交表单
  const onSubmit: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault(); // 阻止submit事件
    setAlertData(() => ({ type: 'yellow', content: '' }));
    const chkRes = chkRegisterParams();
    if (!chkRes) return;
    setLoading(true);
    // 注册
    register(
      registerForm.name,
      registerForm.email,
      registerForm.mobile,
      registerForm.ticket,
      registerForm.password
    )
      .then((res) => {
        if (res.data) {
          setRegisterForm(emptyRegisterForm);
          setLoading(false);
          setAlertData({
            type: 'green',
            content: t('registration_successful'),
          });
        }
        setTimeout(() => {
          window.location.replace('/');
        }, 1000);
      })
      .catch((err) => {
        const error = err?.response?.data?.detail || '';
        setAlertData({
          type: 'red',
          content: t('registration_failed') + error,
        });
        setLoading(false);
      });
  };

  return (
    <>
      <h1 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
        {t('sign_up')}
      </h1>
      <Loading loading={loading} />
      <form className="mt-8 space-y-6">
        <input type="hidden" name="remember" defaultValue="true" />
        <Alert type={alertData.type} content={alertData.content} />
        <div className="shadow-sm">
          <div className="mb-3 ">
            <label htmlFor="name" className="sr-only">
              {t('name')}
            </label>
            <input
              id="name"
              name="name"
              type="text"
              autoComplete="name"
              aria-required
              aria-label="name"
              value={registerForm.name}
              required
              className="bg-white/20 lg:bg-white appearance-none rounded relative block w-full px-3 py-2.5 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-zinc-500 focus:border-zinc-500 focus:z-10 sm:text-sm"
              placeholder={t('name')}
              onChange={onRegisterFormChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="sr-only">
              {t('email')}
            </label>
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              aria-required
              aria-label="email"
              value={registerForm.email}
              required
              className="bg-white/20 lg:bg-white appearance-none rounded relative block w-full px-3 py-2.5 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-zinc-500 focus:border-zinc-500 focus:z-10 sm:text-sm"
              placeholder={t('email')}
              onChange={onRegisterFormChange}
            />
          </div>
          <div className="mb-3">
            <div className="flex flex-row items-center justify-between space-x-2">
              <div className="flex-1">
                <label htmlFor="ticket" className="sr-only">
                  {t('verification_code')}
                </label>
                <input
                  id="ticket"
                  name="ticket"
                  type="text"
                  autoComplete="ticket"
                  aria-required
                  aria-label="ticket"
                  value={registerForm.ticket}
                  required
                  className="bg-white/20 lg:bg-white appearance-none rounded relative block w-full px-3 py-2.5 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-zinc-500 focus:border-zinc-500 focus:z-10 sm:text-sm"
                  placeholder={t('verification_code')}
                  onChange={onRegisterFormChange}
                />
              </div>
              <button
                type="button"
                className={`inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-zinc-600 hover:bg-zinc-500 transition ease-in-out duration-150 ${
                  ticketStatus === 'loading' || countdown > 0
                    ? 'cursor-not-allowed'
                    : ''
                }`}
                disabled={ticketStatus === 'loading' || countdown > 0}
                onClick={onSendTicket}
              >
                {ticketStatus === 'loading' && (
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                )}
                {ticketStatus === 'loading'
                  ? t('sending')
                  : ticketStatus === 'success'
                  ? countdown + t('seconds_before_retry')
                  : ticketStatus === 'error'
                  ? t('resend')
                  : t('send_verification_code')}
              </button>
            </div>
          </div>
          <div className="mb-3 rounded">
            <label htmlFor="mobile" className="sr-only">
              {t('mobile_number')}
            </label>
            <input
              id="mobile"
              name="mobile"
              type="tel"
              autoComplete="mobile"
              aria-required
              aria-label="mobile"
              value={registerForm.mobile}
              required
              className="bg-white/20 lg:bg-white appearance-none rounded relative block w-full px-3 py-2.5 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-zinc-500 focus:border-zinc-500 focus:z-10 sm:text-sm"
              placeholder={t('mobile_number')}
              onChange={onRegisterFormChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="sr-only">
              {t('password')}
            </label>
            <input
              id="password"
              name="password"
              type="password"
              autoComplete="password"
              aria-required
              aria-label="password"
              value={registerForm.password}
              required
              className="bg-white/20 lg:bg-white appearance-none rounded relative block w-full px-3 py-2.5 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-zinc-500 focus:border-zinc-500 focus:z-10 sm:text-sm"
              placeholder={t('password')}
              onChange={onRegisterFormChange}
            />
          </div>
        </div>
        <div>
          <button
            onClick={onSubmit}
            className="group relative w-full flex justify-center py-2.5 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-zinc-600 hover:bg-zinc-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-zinc-500"
          >
            <span className="absolute left-0 inset-y-0 flex items-center pl-3">
              <LockClosedIcon
                className="h-5 w-5 text-zinc-500 group-hover:text-zinc-400"
                aria-hidden="true"
              />
            </span>
            {t('sign_up')}
          </button>
        </div>
        <div className="flex flex-row-reverse items-center justify-between">
          <div className="text-sm">
            <label className="font-medium text-zinc-600 hover:text-zinc-500 cursor-pointer">
              <Link to="/">{t('login_now')}</Link>
            </label>
          </div>
        </div>
      </form>
    </>
  );
}

export default Register;
