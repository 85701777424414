export function Loading({ loading }: { loading: boolean }) {
  return loading ? (
    <div className="fixed top-0 left-0 z-50 flex h-screen w-screen items-center justify-center bg-white opacity-60">
      <svg
        className="animate-spin"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="100"
        height="100"
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M12 3a9 9 0 0 1 9 9h-2a7 7 0 0 0-7-7V3z" />
      </svg>
    </div>
  ) : null;
}

export default Loading;
