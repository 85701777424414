/* eslint-disable @typescript-eslint/no-unused-vars */
import { stringify } from 'qs';
import axios, { AxiosRequestConfig, AxiosPromise } from 'axios';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);
interface Request {
  (options: AxiosRequestConfig): AxiosPromise<any>;
}

interface Request {
  get?: (
    url: string,
    data?: Record<string, any>,
    options?: AxiosRequestConfig
  ) => AxiosPromise<any>;
  post?: (
    url: string,
    data?: Record<string, any>,
    options?: AxiosRequestConfig
  ) => AxiosPromise<any>;
  put?: (
    url: string,
    data?: Record<string, any>,
    options?: AxiosRequestConfig
  ) => AxiosPromise<any>;
  delete?: (
    url: string,
    data?: Record<string, any>,
    options?: AxiosRequestConfig
  ) => AxiosPromise<any>;
  patch?: (
    url: string,
    data?: Record<string, any>,
    options?: AxiosRequestConfig
  ) => AxiosPromise<any>;
}

interface Config {
  axiosConfig?: AxiosRequestConfig;
}

export function createRequest(baseURL: string, config?: Config) {
  const requestConfig: AxiosRequestConfig<any> = {
    baseURL,
    timeout: 8000,
    ...config?.axiosConfig,
  };
  const instance = axios.create(requestConfig);

  const request: Request = (options: AxiosRequestConfig) => {
    options.method ||= 'get';
    if (options.method.toLowerCase() === 'get') {
      const arr = Object.entries(options.data || options.params || {});
      // 过滤一些空值，对 GET 请求中的参数进行转换
      const params = Object.fromEntries(
        arr
          .filter(([_, value]: any) => {
            // 过滤空值
            if (value === null || value === undefined || value === '')
              return false;
            // 过滤数组中的空值
            if (
              Array.isArray(value) &&
              value.filter((i) => i != null && i !== '').length === 0
            )
              return false;
            // 过滤空对象
            if (typeof value === 'object' && Object.keys(value).length === 0)
              return false;
            return true;
          })
          ?.map(([key, value]: any) => {
            if (Array.isArray(value)) return [key, JSON.stringify(value)];
            // 统一处理日期范围筛选
            if (
              dayjs(value).isValid() &&
              (key.endsWith('__lte') ||
                key.endsWith('__lt') ||
                key.endsWith('__gt') ||
                key.endsWith('__gte'))
            ) {
              const newKey = key.endsWith('__lte')
                ? key.replace('__lte', '__lt')
                : key;
              const computeNewValue = (
                value: string,
                unit: dayjs.ManipulateType,
                format: string
              ) => {
                return (
                  key.endsWith('__lte')
                    ? dayjs(value).add(1, unit)
                    : dayjs(value)
                ).format(format);
              };
              if (dayjs(value, 'YYYY', true).isValid()) {
                return [newKey, computeNewValue(value, 'year', 'YYYY-MM-DD')];
              }
              if (dayjs(value, 'YYYY-MM', true).isValid()) {
                return [newKey, computeNewValue(value, 'month', 'YYYY-MM-DD')];
              }
              if (dayjs(value, 'YYYY-MM-DD', true).isValid()) {
                return [newKey, computeNewValue(value, 'day', 'YYYY-MM-DD')];
              }
              if (dayjs(value, 'YYYY-MM-DD HH', true).isValid()) {
                return [
                  newKey,
                  computeNewValue(value, 'hour', 'YYYY-MM-DD HH:mm:ss'),
                ];
              }
              if (dayjs(value, 'YYYY-MM-DD HH:mm', true).isValid()) {
                return [
                  newKey,
                  computeNewValue(value, 'minute', 'YYYY-MM-DD HH:mm:ss'),
                ];
              }
            }
            return [key, value];
          })
      );
      options.params = params;
    }
    options.paramsSerializer = (params) => stringify(params);
    return instance(options);
  };

  (['get', 'post', 'put', 'delete', 'patch'] as const).forEach((type) => {
    request[type] = <D = any>(
      url: string,
      data?: D,
      options?: AxiosRequestConfig<D>
    ) =>
      request({
        url,
        data,
        method: type,
        ...options,
      });
  });

  return { request, instance };
}
