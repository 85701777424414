// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

export const environment = {
  production: false,
  apiUrl: 'https://dev.digivisium.com:8010/api',
  sites: [],
  client: '',
  icpNumber: '沪ICP备2021036811号-2',
};
