import { Outlet } from 'react-router-dom';
import { environment } from '../environments/environment';
import { useTranslation } from 'react-i18next';
import { ChangeEvent } from 'react';

export function Root() {
  const client = environment.client;
  const icp = environment.icpNumber;
  const { i18n } = useTranslation();
  const changeLanguage = (event: ChangeEvent<HTMLSelectElement>) => {
    i18n.changeLanguage(event.target.value);
  };
  return (
    <div
      className={`relative h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 ${
        client === 'dinger' ? `bg-[url('/assets/images/bg.jpg')] bg-cover` : ''
      }`}
    >
      <div
        className={`max-w-sm w-full h-screen z-20 ${
          client === 'dinger' ? '' : 'lg:mr-20'
        } relative`}
      >
        <div className="flex h-full flex-col justify-center space-y-8">
          <div>
            {client === 'dinger' ? null : (
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="cat"
                className="w-24 h-24"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M290.59 192c-20.18 0-106.82 1.98-162.59 85.95V192c0-52.94-43.06-96-96-96-17.67 0-32 14.33-32 32s14.33 32 32 32c17.64 0 32 14.36 32 32v256c0 35.3 28.7 64 64 64h176c8.84 0 16-7.16 16-16v-16c0-17.67-14.33-32-32-32h-32l128-96v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V289.86c-10.29 2.67-20.89 4.54-32 4.54-61.81 0-113.52-44.05-125.41-102.4zM448 96h-64l-64-64v134.4c0 53.02 42.98 96 96 96s96-42.98 96-96V32l-64 64zm-72 80c-8.84 0-16-7.16-16-16s7.16-16 16-16 16 7.16 16 16-7.16 16-16 16zm80 0c-8.84 0-16-7.16-16-16s7.16-16 16-16 16 7.16 16 16-7.16 16-16 16z"
                ></path>
              </svg>
            )}
          </div>
          <Outlet />
        </div>
      </div>
      {client === 'dinger' ? null : (
        <div className="absolute opacity-50 blur-lg lg:relative lg:opacity-100 lg:blur-none">
          <img
            className="h-screen"
            src="../assets/images/wallpaper.png"
            alt="advertisement"
          />
        </div>
      )}
      {icp && (
        <div className="absolute bottom-5 text-center w-full">
          <div className="flex flex-row justify-center">
            <div>
              <a
                className="inline-block text-center text-sm text-gray-500"
                href="https://beian.miit.gov.cn/"
                target="_blank"
                rel="noreferrer"
              >
                {icp}
              </a>
            </div>
            <select
              onChange={changeLanguage}
              value={i18n.resolvedLanguage}
              className="ml-4 p-1 text-sm text-gray-500 bg-white border border-gray-300 rounded-md w-20 z-50"
            >
              <option value="zh">中文</option>
              <option value="en">English</option>
            </select>
          </div>
        </div>
      )}
    </div>
  );
}
