import { request } from '../utils';

export const login = (username: string, password: string) => {
  return request({
    url: '/authentication/login/',
    method: 'post',
    data: {
      username,
      password,
      medium: 'desktop',
    },
  });
};

export const register = (
  name: string,
  email: string,
  mobile: string,
  ticket: string,
  password: string
) => {
  return request({
    url: '/authentication/register-user/',
    method: 'post',
    data: {
      name,
      email,
      mobile,
      ticket,
      password,
    },
  });
};

// validate the token
export const validateToken = (token: string) => {
  return request({
    url: '/authentication/authenticate-user/',
    method: 'post',
    data: {
      token,
    },
  });
};

// change password
export const changePassword = (
  token: string,
  old_password: string,
  new_password: string
) => {
  return request({
    url: '/authentication/change-password/',
    method: 'post',
    data: {
      old_password,
      new_password,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// reset password
export const resetPassword = (
  email: string,
  ticket: string,
  password: string
) => {
  return request({
    url: '/authentication/reset-password/',
    method: 'post',
    data: {
      email,
      ticket,
      new_password: password,
    },
  });
};

// get ticket to reset password
export const getTicketToReset = (email: string) => {
  return request({
    url: '/authentication/forget-password/',
    method: 'post',
    data: {
      email,
    },
  });
};

// get ticket to register
export const getTicketToRegister = (email: string) => {
  return request({
    url: '/authentication/register-ticket/',
    method: 'post',
    data: {
      email,
    },
  });
};
