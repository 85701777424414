/* eslint-disable no-useless-escape */
import {
  useState,
  useCallback,
  ChangeEventHandler,
  MouseEventHandler,
} from 'react';
import { LockClosedIcon } from '@heroicons/react/solid';
import { redirect, Link } from 'react-router-dom';
import { validateToken, changePassword } from '../api';
import { Loading, Alert } from '../components';
import type { AlertProps } from '../components';
import { z } from 'zod';
import { environment } from '../environments/environment';
import { useTranslation } from 'react-i18next';

export async function ChangePwdLoader() {
  const tokenInLocalStorage = localStorage.getItem('token');
  if (tokenInLocalStorage) {
    try {
      const { data } = await validateToken(tokenInLocalStorage);
      if (data?.user) {
        return true;
      } else {
        localStorage.removeItem('token');
        redirect('/');
      }
    } catch (e) {
      console.error(e);
      localStorage.removeItem('token');
      redirect('/');
    }
  } else {
    return redirect('/');
  }
  return true;
}

export function ChangePwd() {
  const { t } = useTranslation('loginsystem');

  const schema = z
    .object({
      oldPassword: z
        .string()
        .min(1, t('old_password_cannot_be_empty'))
        .regex(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#\$%\^&\*\+\-\[\]\\\|:\?<>~\)\("'\.,=_`/;{}]{8,25}$/,
          t('old_password_format_incorrect')
        ),
      newPassword: z
        .string()
        .min(1, t('new_password_cannot_be_empty'))
        .regex(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#\$%\^&\*\+\-\[\]\\\|:\?<>~\)\("'\.,=_`/;{}]{8,25}$/,
          t('password_must_contain_uppercase_lowercase_numbers_length_8_25')
        ),
      confirmPassword: z.string().min(1, t('confirm_password_cannot_be_empty')),
    })
    .required()
    .refine((data) => data.newPassword === data.confirmPassword, {
      message: t('passwords_do_not_match'),
      path: ['confirmPassword'],
    });

  type ChangePwdForm = z.infer<typeof schema>;

  const client = environment.client;
  const [loading, setLoading] = useState(false);
  const [alertData, setAlertData] = useState<AlertProps>({
    type: 'yellow',
    content: '',
  });
  const [changeForm, setChangeForm] = useState<ChangePwdForm>({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const onChangeFormChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const { name, value } = event.target;
      setChangeForm((prev) => ({ ...prev, [name]: value }));
    },
    [setChangeForm]
  );
  // 校验登录表单参数
  const checkChangeForm = () => {
    const result = schema.safeParse(changeForm);
    if (!result.success) {
      setAlertData({
        type: 'yellow',
        content: result.error.issues[0].message,
      });
      return false;
    }
    setAlertData({
      type: 'yellow',
      content: '',
    });
    return true;
  };
  // 提交表单
  const onSubmit: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault(); // 阻止submit事件
    setAlertData(() => ({ type: 'yellow', content: '' }));
    const chkRes = checkChangeForm();
    if (!chkRes) return;
    setLoading(true);
    const token = localStorage.getItem('token') as string;
    changePassword(token, changeForm.oldPassword, changeForm.newPassword)
      .then((res) => {
        if (res.data) {
          setAlertData({
            type: 'green',
            content: t('change_successful_please_login_again'),
          });
          setLoading(false);
          // redirect to login page
          setTimeout(() => {
            window.location.replace('/');
          }, 1000);
        } else {
          setAlertData({
            type: 'red',
            content: t('change_failed'),
          });
          setLoading(false);
        }
      })
      .catch((e) => {
        console.error(e);
        const error = e?.response?.data?.detail || '';
        setAlertData({
          type: 'red',
          content: t('change_failed') + t('comma') + error,
        });
        setLoading(false);
      });
  };
  return (
    <>
      <h1 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
        {t('change_password')}
      </h1>
      <Loading loading={loading} />
      <form className="mt-8 space-y-6">
        <input type="hidden" name="remember" defaultValue="true" />
        <input
          aria-hidden
          type="text"
          name="username"
          autoComplete="username email"
          className="hidden"
        />
        <Alert type={alertData.type} content={alertData.content} />
        <div className="shadow-sm">
          <div className="rounded mb-3">
            <label htmlFor="old-password" className="sr-only">
              {t('old_password')}
            </label>
            <input
              id="old-password"
              name="oldPassword"
              aria-label={t('old_password')}
              aria-required
              type="password"
              autoComplete="current-password"
              required
              className="bg-white/20 lg:bg-white appearance-none rounded relative block w-full px-3 py-2.5 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-zinc-500 focus:border-zinc-500 focus:z-10 sm:text-sm"
              placeholder={t('old_password')}
              value={changeForm.oldPassword}
              onChange={onChangeFormChange}
            />
          </div>
          <div className="rounded mb-3">
            <label htmlFor="new-password" className="sr-only">
              {t('new_password')}
            </label>
            <input
              id="new-password"
              name="newPassword"
              aria-label={t('new_password')}
              aria-required
              type="password"
              autoComplete="new-password"
              required
              className="bg-white/20 lg:bg-white appearance-none rounded relative block w-full px-3 py-2.5 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-zinc-500 focus:border-zinc-500 focus:z-10 sm:text-sm"
              placeholder={t('new_password')}
              value={changeForm.newPassword}
              onChange={onChangeFormChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="confirm-new-password" className="sr-only">
              {t('confirm_new_password')}
            </label>
            <input
              id="confirm-new-password"
              name="confirmPassword"
              aria-label={t('confirm_new_password')}
              aria-required
              type="password"
              autoComplete="new-password"
              required
              className="bg-white/20 lg:bg-white appearance-none rounded relative block w-full px-3 py-2.5 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-zinc-500 focus:border-zinc-500 focus:z-10 sm:text-sm"
              placeholder={t('confirm_new_password')}
              value={changeForm.confirmPassword}
              onChange={onChangeFormChange}
            />
          </div>
        </div>
        <div>
          <button
            onClick={onSubmit}
            className="group relative w-full flex justify-center py-2.5 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-zinc-600 hover:bg-zinc-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-zinc-500"
          >
            <span className="absolute left-0 inset-y-0 flex items-center pl-3">
              <LockClosedIcon
                className="h-5 w-5 text-zinc-500 group-hover:text-zinc-400"
                aria-hidden="true"
              />
            </span>
            {t('confirm_change')}
          </button>
        </div>
        {client === 'dinger' ? null : (
          <div className="flex flex-row-reverse items-center justify-between">
            <div className="text-sm">
              <label className="font-medium text-zinc-600 hover:text-zinc-500 cursor-pointer">
                <Link to={'/reset-password'}>{t('reset_password')}</Link>
              </label>
            </div>
          </div>
        )}
      </form>
    </>
  );
}

export default ChangePwd;
