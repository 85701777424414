export interface AlertProps {
  type:
    | 'blue'
    | 'purple'
    | 'green'
    | 'red'
    | 'yellow'
    | 'indigo'
    | 'gray'
    | 'darkGray';
  content: string;
}

export function Alert({ type, content }: AlertProps) {
  const blue = (
    <div
      className="bg-blue-100 rounded-lg py-5 px-6 text-base text-blue-700 mb-3"
      role="alert"
    >
      {content}
    </div>
  );
  const purple = (
    <div
      className="bg-purple-100 rounded-lg py-5 px-6 text-base text-purple-700 mb-3"
      role="alert"
    >
      {content}
    </div>
  );
  const green = (
    <div
      className="bg-green-100 rounded-lg py-5 px-6 text-base text-green-700 mb-3"
      role="alert"
    >
      {content}
    </div>
  );

  const red = (
    <div
      className="bg-red-100 rounded-lg py-5 px-6 text-base text-red-700 mb-3"
      role="alert"
    >
      {content}
    </div>
  );

  const yellow = (
    <div
      className="bg-yellow-100 rounded-lg py-5 px-6 text-base text-yellow-700 mb-3"
      role="alert"
    >
      {content}
    </div>
  );

  const indigo = (
    <div
      className="bg-indigo-100 rounded-lg py-5 px-6 text-base text-indigo-700 mb-3"
      role="alert"
    >
      {content}
    </div>
  );

  const gray = (
    <div
      className="bg-gray-50 rounded-lg py-5 px-6 text-base text-gray-500 mb-3"
      role="alert"
    >
      {content}
    </div>
  );
  const darkGray = (
    <div
      className="bg-gray-300 rounded-lg py-5 px-6 text-base text-gray-800 mb-3"
      role="alert"
    >
      {content}
    </div>
  );

  const alerts = {
    blue,
    purple,
    green,
    red,
    yellow,
    indigo,
    gray,
    darkGray,
  };

  return (
    <div>{Object.keys(alerts).includes(type) && content && alerts[type]}</div>
  );
}

export default Alert;
