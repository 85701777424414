import { createRequest } from '@quantum/request';
import { AxiosResponse } from 'axios';
import { environment } from '../environments/environment';
import i18n from '../i18n';

const baseUrl = environment.apiUrl;
const { request, instance } = createRequest(baseUrl);

instance.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    let result: AxiosResponse<any, any>['data'] = null;
    const { code, data, paginator, detail } = response.data;
    if (code === 2000) {
      if (
        typeof paginator === 'object' &&
        paginator !== null &&
        Object.keys(paginator).length
      ) {
        result = {
          data,
          pagination: paginator,
        };
      } else {
        result = data;
      }
      result = Object.assign(response, { data: result });
    } else if (code === 4001) {
      // redirectToLogin(errorI18n('access_token_error'));
      // result = Promise.reject(new Error(errorI18n('access_token_error')));
      result = Promise.reject(
        new Error(i18n.t('login_issues', { ns: 'loginsystem' }))
      );
    } else {
      // const msg: string = typeof detail === 'string' && detail ? detail : errorI18n(errorCode.get(code) as string);
      // message.error('msg')
      // result = Promise.reject(new Error(msg));
      result = Promise.reject(
        new Error(i18n.t('unknown_issue', { ns: 'loginsystem' }))
      );
    }
    return result;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export { request };
