import { useTranslation } from 'react-i18next';

export function NotFound() {
  const { t } = useTranslation('loginsystem');
  return (
    <div className="flex h-screen w-screen flex-col items-center justify-center space-y-8">
      <h1 className="text-bold mb-0 font-mono text-8xl">404</h1>
      <p className="mb-0 text-2xl">{t('page_not_exist')}</p>
    </div>
  );
}

export default NotFound;
